import React, { useEffect } from 'react';
import './App.css';
import fuelrpImg from './icon/fuelrp.png';
import slushyrpImg from './icon/slushyrp.png';

const App = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    const text = 'loxitopka.pl';
    const h1 = document.querySelector('#animated-text');

    const animateText = () => {
      h1.innerHTML = '';
      text.split('').forEach((letter, index) => {
        const span = document.createElement('span');
        span.textContent = letter;
        span.classList.add('fade-in');
        span.style.animationDelay = `${index * 0.1}s`;
        h1.appendChild(span);
      });
    };

    animateText();

    const secondSection = document.querySelector('#projects-section');
    const cards = document.querySelectorAll('.card');
    let lastScrollTop = 0;

    const handleScroll = () => {
      const sectionPosition = secondSection.getBoundingClientRect().top;
      const screenPosition = window.innerHeight / 1.3;

      if (sectionPosition < screenPosition) {
        secondSection.classList.add('visible');
        cards.forEach((card, index) => {
          setTimeout(() => {
            card.classList.add('visible');
          }, index * 300);
        });
      }

      let st = window.pageYOffset || document.documentElement.scrollTop;

      if (st < lastScrollTop) {
        animateText();
      }

      lastScrollTop = st <= 0 ? 0 : st;
    };

    const startOneko = () => {
      fetch("https://raw.githubusercontent.com/adryd325/oneko.js/8fa8a1864aa71cd7a794d58bc139e755e96a236c/oneko.js")
          .then((response) => response.text())
          .then((scriptContent) =>
              scriptContent
                  .replace("./oneko.gif", "https://raw.githubusercontent.com/adryd325/oneko.js/14bab15a755d0e35cd4ae19c931d96d306f99f42/oneko.gif")
                  .replace("(isReducedMotion)", "(false)")
          )
          .then((modifiedScript) => eval(modifiedScript));
  };
  
  startOneko();

    window.addEventListener('scroll', handleScroll);

    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    const handleKeyDown = (e) => {
      if (e.key === 'F12') {
        e.preventDefault();
      }
    };

    window.addEventListener('contextmenu', handleContextMenu);
    window.addEventListener('keydown', handleKeyDown);


    const cursor = document.createElement('div');
    cursor.className = 'custom-cursor';
    document.body.appendChild(cursor);

    const mouseMoveHandler = (e) => {
      cursor.style.left = `${e.pageX}px`;
      cursor.style.top = `${e.pageY}px`;
    };

    window.addEventListener('mousemove', mouseMoveHandler);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('contextmenu', handleContextMenu);
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('mousemove', mouseMoveHandler);
      document.body.removeChild(cursor);
    };
  }, []);

  

  return (
    <div>
      <main>
        <section className="first-section">
          <h1 id="animated-text"></h1>
        </section>

        <div className="scroll-indicator">
          <div className="mouse-icon">
            <div className="wheel"></div>
          </div>
          <p className="scroll-text">Zjedź niżej</p>
        </div>

        <section className="second-section" id="projects-section">
          <h2>Moje Projekty Fivem</h2>
          <div className="project-cards">
            <div className="card" id="fuel-rp">
              <a className="card-content">
                <img src={fuelrpImg} alt="Logo FuelRP" />
                <h3>FuelRP</h3>
                <div className="project-date">2020-2022</div>
              </a>
            </div>
            <div className="card" id="slushy-rp">
              <div className="card-content">
                <img src={slushyrpImg} alt="Logo SlushyRP" />
                <h3>SlushyRP</h3>
                <div className="project-date">2023</div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer>
        <p className="copyright">
          Copyright © 2024 loxitopka.pl. All rights reserved.
          <a href="https://discord.gg/vMwauN7vRC" target="_blank" rel="noopener noreferrer" style={{ marginLeft: '10px' }}>
            <i className="fab fa-discord" style={{ fontSize: '20px', color: '#7289da' }}></i>
          </a>
        </p>
      </footer>
    </div>
  );
};

export default App;
